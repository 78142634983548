export default {
  apple: ["grass", "potato"],
  apricot: ["grass", "tomato"],
  basil: ["rue", "silverbeet"],
  beans: ["chives", "fennel", "garlic", "onions", "shallots"],
  beets: ["climbing beans", "tomato"],
  "broad beans": ["chives", "fennel", "garlic", "onions"],
  broccoli: ["rue", "strawberries"],
  "bush beans": ["chives", "garlic", "onions"],
  cabbage: ["garlic", "rue", "strawberries", "tomato"],
  carrots: ["parsnip"],
  cauliflower: ["rue", "strawberries"],
  celery: ["parsnip", "potato"],
  chamomile: ["mints"],
  cherry: ["grass", "potato"],
  chives: ["beans", "broad beans", "bush beans", "climbing beans"],
  "climbing beans": ["beets", "chives", "garlic", "onions", "sunflower"],
  coriander: ["fennel"],
  cucumber: ["potato", "sage"],
  fennel: ["beans", "broad beans", "coriander", "lavender", "tomato"],
  garlic: [
    "beans",
    "broad beans",
    "bush beans",
    "climbing beans",
    "cabbage",
    "peas",
    "strawberries",
    "sunflower",
  ],
  grass: ["apple", "apricot", "cherry", "fruit trees", "sage"],
  lavender: ["fennel"],
  lettuce: ["parsley"],
  marigold: ["tomato"],
  mints: ["chamomile", "parsley"],
  onions: ["beans", "broad beans", "bush beans", "climbing beans", "peas"],
  parsley: ["lettuce", "mints"],
  parsnip: ["carrots", "celery"],
  peas: ["garlic", "onions", "shallots"],
  potato: [
    "apple",
    "celery",
    "cherry",
    "cucumber",
    "pumpkin",
    "raspberry",
    "rosemary",
    "sunflower",
    "tomato",
  ],
  raspberry: ["potato"],
  rosemary: ["potato", "tomato"],
  roses: ["tomato"],
  rue: ["basil", "cabbage", "sage"],
  sage: ["cucumber", "grass", "rue"],
  shallots: ["beans", "peas"],
  silverbeet: ["basil"],
  strawberries: ["broccoli", "cabbage", "cauliflower", "garlic"],
  sunflower: ["climbing beans", "garlic", "potato"],
  tomato: ["apricot", "beets", "cabbage", "marigold", "roses"],
};
