import React from "react";
import Garden from "../components/Garden";
import { ReactComponent as Logo } from "../logo.svg";

const styles = {
  nav: {
    padding: ".85rem 1.5rem",
    boxShadow: "0 1px 1px rgba(0, 0, 0, .15)",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
  },
  main: {
    padding: "1rem 10px",
  },
  sideBySide: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
  },
};

const Nav = _ => (
  <nav style={styles.nav}>
    <Logo style={{ height: "23px" }} />
  </nav>
);
const Demo = _ => (
  <React.Fragment>
    <Nav />
    <main style={styles.main}>
      <section style={styles.sideBySide}>
        <div style={{ flex: "1", marginLeft: "2rem" }}>
          <h1>Anyone should be able to garden</h1>
          <p>
            Plant relationships are a{" "}
            <a
              href="https://codepen.io/bloom-dan/full/jVWWwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              complicated network.
            </a>{" "}
            Tomatoes, for instance, like basil. Basil likes cucumbers. Cucumbers
            get along with cabbage. Cabbage hates tomatoes, though.
          </p>
          <p>
            To really understand this and plant a happy garden, you'd need a
            lifetime (or three)--and we haven't even started talking about
            variables like grow zone, soil preference or days to maturity.
          </p>
          <p>
            Able Farm is a project aimed at making it easier to plan a smart
            garden. You can see our initial work in action by dragging the
            plants around.
          </p>
          <p>Check back soon to create a garden of your own!</p>
        </div>
        <div style={{ flex: "2" }}>
          <Garden />
        </div>
      </section>
    </main>
  </React.Fragment>
);

export default Demo;
